<template>
  <div>
    <div class="index1">
      <div class="box1-title">联系我们</div>
      <div class="border"></div>
      <div class="text">
        金华市天华网络科技有限公司成立于2007年，公司主要从事信息行业应用软件开发和技术服务，基于移动互联网与物联网、大数据和云计算<br />
        等技术，开发大数据管理服务云平台。
      </div>
    </div>
    <div class="index2" ref="box">
      <div id="l-map"></div>
      <div class="contrac_row">
        <div class="address_box">
          <img src="../../assets/homepage/phone.png" />
          <div class="address_desc">
            <div class="address_title">联系电话：</div>
            <div class="address_desc">400-780-0159</div>
          </div>
        </div>
        <div class="address_box">
          <img src="../../assets/homepage/email.png" />
          <div class="address_desc">
            <div class="address_title">电子邮箱：</div>
            <div class="address_desc">tianhua@skygovern.com</div>
          </div>
        </div>
        <div class="address_box">
          <img src="../../assets/homepage/website.png" />
          <div class="address_desc">
            <div class="address_title">公司官网：</div>
            <div class="address_desc">www.skygovern.com</div>
          </div>
        </div>
        <div class="address_box">
          <img src="../../assets/homepage/address.png" />
          <div class="address_desc">
            <div class="address_title">
              公司地址：<span class="address_desc"
                >金华市金东区多湖街道光南路广润翰城北门4幢120室</span
              >
            </div>
            <!-- <div class="address_desc"></div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "product",
  data() {
    return {};
  },

  mounted() {
    //初始化地图
    let map = new BMap.Map("l-map", { enableMapClick: false });
    //中心点(经度，维度)
    let point = new BMap.Point(119.720221, 29.107633);
    let pointImg = require("@/assets/homepage/point.png");
    //自定义标注图片
    let myIcon = new BMap.Icon(pointImg, new BMap.Size(60, 60), {
      anchor: new BMap.Size(10, 25),
    });
    //创建标注
    let marker = new BMap.Marker(point, { icon: myIcon }); // 创建标注
    //将标注插入地图
    map.addOverlay(marker);
    //设置中心点和缩放级别
    map.centerAndZoom(point, 17);
    //鼠标滚轮缩放
    map.enableScrollWheelZoom();
    let labelStyle = {
      color: "rgba(51, 51, 51, 1)",
      fontFamily: "SourceHanSansSC-regular",
      fontWeight: "500",
      borderRadius: "5px",
      backgroundColor: "rgba(255, 255, 255, 0.7)",
      fontSize: "18px",
      lineHeight: "70px",
      fontFamily: "微软雅黑",
      width: "274px",
      height: "70px",
      textAlign: "center",
      boxShadow: "0px 0px 10px 0px rgba(52, 111, 255, 0.20)",
      border: "none",
    };

    var opts = {
      position: point, // 指定文本标注所在的地理位置
      offset: new BMap.Size(-120, -100), // 设置文本偏移量
    };
    let label = new BMap.Label("金华市天华网络科技有限公司", opts); // 创建文本标注对象
    label.setStyle(labelStyle); // 自定义文本标注样式
    map.addOverlay(label);

    // map.openInfoWindow(infoWindow, opts.position);
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.index1 {
  height: 365px;
  background: url("~@/assets/contrac/banner.png") no-repeat center center;
  background-size: auto 100%;
  text-align: left;
  padding-left: 340px;
}
.box1-title {
  font-size: 28px;
  font-weight: bold;
  color: rgba(52, 111, 255, 1);
  padding-top: 139px;
  margin-bottom: 16px;
}
.border {
  width: 100px;
  height: 10px;
  line-height: 20px;
  border-radius: 10px;
  background-color: rgba(52, 111, 255, 1);
  margin-bottom: 16px;
}

.text {
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  line-height: 20px;
}

.index2 {
  margin: 0 auto;
  margin-top: -40px;
  width: 1500px;
  height: 665px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
  box-shadow: 0px 0px 10px 0px rgba(52, 111, 255, 0.3);
  box-sizing: border-box;
  padding: 30px;
  .contrac_row {
    padding: 30px 20px 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .address_box {
      display: flex;
      flex-direction: row;
      flex: 1;
      .address_desc {
        color: rgba(51, 51, 51, 1);
        font-size: 20px;
        text-align: left;
        line-height: 30px;
        .address_title {
          font-weight: bold;
        }
        .address_desc {
          font-weight: bold;
          font-size: 16px;
          color: rgba(102, 102, 102, 1);
        }
      }
    }
    img {
      width: 60px;
      height: 60px;
      margin-right: 20px;
    }
  }
}
#l-map {
  height: 515px;
  width: 100%;
  flex: 1;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(52, 111, 255, 0.3);
  margin-right: 20px;
}
@media screen and (min-width: 1200px) and (max-width: 1300px) {
}
</style>
